<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
   <div class="vx-col" >
 <div class="vx-row">
    <feather-icon v-if="EnglishLan" @click="back()" icon="ChevronLeftIcon" style="width: 30px;cursor: pointer;">
      </feather-icon>
      <feather-icon v-else @click="back()" icon="ChevronRightIcon" style="width: 30px;cursor: pointer;">
      </feather-icon>
      <h2 style="color:#0082D5" class="mb-2" >{{$t('AddAssignDoctorstoSurgeriesTreatments')}}</h2>

     </div>
     <div class="vx-row ml-3">

      <h2 >{{$t('FilterBySpecialty')}}</h2>
     </div>


     <div class="vx-row mt-3 ">
 <div class="vx-row flex-1" v-for="item in HospitalParentSpecialties" :key="item.ID">
      <div class="vx-row ">
           <vs-button
                    size="medium"
                    style="border-radius: 34px; opacity: 1"
                    :color="selectedSpecialityFilter.ID == item.ID ? '#004477' : 'white'"
                    :style="[
                      selectedSpecialityFilter.ID == item.ID
                        ? {}
                        : { border: ' 2px solid #1F1F1F' },
                    ]"
                    @click="select(item)"
                    class="m-1"
                    ><span
                      :style="[
                        selectedSpecialityFilter.ID == item.ID
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ item.Specialty.Name}}</span
                    >
                  </vs-button>
                  </div>
 </div>
  </div>

  <!-- sub specialities -->
   <div class="vx-row ml-3" v-if="HospitalChiledSpecialties.length>0">


      <h2 >{{$t('FilterBySubSpecialty')}}</h2>
     </div>


     <div class="vx-row mt-3 ">
 <div class="vx-row flex-1" v-for="item in HospitalChiledSpecialties" :key="item.ID">
      <div class="vx-row ">
           <vs-button
                    size="medium"
                    style="border-radius: 34px; opacity: 1"
                    :color="selectedChiledSpecialityFilter.ID == item.ID ? '#004477' : 'white'"
                    :style="[
                      selectedChiledSpecialityFilter.ID == item.ID
                        ? {}
                        : { border: ' 2px solid #1F1F1F' },
                    ]"
                    @click="selectChiled(item)"
                    class="m-1"
                    ><span
                      :style="[
                        selectedChiledSpecialityFilter.ID == item.ID
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ item.Specialty.Name}}</span
                    >
                  </vs-button>
                  </div>
 </div>
  </div>

<!-- surgeries and treatments // Medical procedures-->
   <div class="vx-row ml-3" v-if="searchSurgeries.length>0">


      <h2 >{{$t('FilterBySurgery')}}</h2>
     </div>


     <div class="vx-row mt-3 ">
 <div class="vx-row flex-1" v-for="item in searchSurgeries" :key="item.ID">
      <div class="vx-row ">
           <vs-button
                    size="medium"
                    style="border-radius: 34px; opacity: 1"
                    :color="selectedSurgeryFilter.ID == item.ID ? '#004477' : 'white'"
                    :style="[
                      selectedSurgeryFilter.ID == item.ID
                        ? {}
                        : { border: ' 2px solid #1F1F1F' },
                    ]"
                    @click="selectSurgery(item)"
                    class="m-1"
                    ><span
                      :style="[
                        selectedSurgeryFilter.ID == item.ID
                          ? { color: 'white' }
                          : { color: 'black' },
                      ]"
                    >
                      {{ item.Surgery.Name}}</span
                    >
                  </vs-button>
                  </div>
 </div>
  </div>

  <!-- add doctors -->
    <div  class="vx-row" >
      <div class="vx-col lg:w-1/5 w-full w-full mt-5"></div>
  <div class="vx-col lg:w-1/3 w-full w-full mt-5">
  <h2 style="margin-left:-30%" class="mb-5">{{$t('AddDoctors')}}</h2>
         <vs-card class="vx-col" style="height: 50vh;overflow-y: scroll;">
           <h3 >{{$t('Doctors')}}</h3>
         <div class="Active mt-3 ml-5 vx-row" v-if="(searchDoctorModel.SpecialtyID>0)">
               <feather-icon
               style="width:15px"
        icon="PlusCircleIcon"
        class="cursor-pointer"
      ></feather-icon>
           <u @click="ShowCreateDoctorProfile=true"  class="mt-1 ml-2">{{$t("CreateNewDoctorProfile")}}</u></div>

                <vs-input   v-model="search.DoctorName"  placeholder="search" class="w-full" name="search"   />
                    <div v-for="item in filterDoctor" :key="item.ID" :class="item.IsSelected?'Active':'InActive'"  class="shadow-md w-full mt-2">
                         <p class="m-5 p-3 vx-row"   >

          <vs-checkbox v-model="item.IsSelected" @input="DoctorChecked(item)" :disabled="!(searchDoctorModel.HospitalSurgeryID>0)"></vs-checkbox>
           {{ item.Name }}
                        </p>
                    </div>
            </vs-card>

        </div>

        <div class="vx-col lg:w-1/3 w-full w-full" style="margin-top:5%">
         <vs-card class="vx-col" style="height: 50vh;overflow-y: scroll;" >
  <h2 class="m-3 Active">{{$t('Doctors')}}</h2>

                    <div v-for="item in HospitalDoctors" :key="item.ID"  class="InActive shadow-md w-full mt-2">
                         <li style="margin:5%;padding:3%;font-size:medium">{{ item.Doctor.Name }}</li>
                    </div>
            </vs-card>
        <div class="vx-row m-10" >
         <div class="lg:w-2/3 mt-3" ></div>
         <div class="lg:w-1/3"><vs-button
         :disabled="!(searchDoctorModel.HospitalSurgeryID>0)"
          color="#004477"
          @click="SaveDoctors()"
          class="vx-row mt-3 customizer-btn text-sm text-white"
          ><h6 style="color:white">{{ $t("Submit") }}</h6>
        </vs-button></div>
        </div>
        </div>
    </div>

    <vs-popup fullscreen title="" :active.sync="ShowCreateDoctorProfile"
    >
      <CreateDoctorProfile v-if="ShowCreateDoctorProfile" :searchDoctorModel="searchDoctorModel"
       @closePop="ShowCreateDoctorProfile=false"></CreateDoctorProfile>
    </vs-popup>


 </div>
</template>

<script>

//import LoginFirebase from "./LoginFirebase.vue"
//import LoginAuth0 from "./LoginAuth0.vue"
 import axios from "@/axios.js"
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";
import moduleHospital from "@/store/hospital/moduleHospital.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import CreateDoctorProfile from "@/views/hospital/CreateDoctorProfile.vue";

import { domain,mainSetting } from '@/gloabelConstant.js';
export default {
  data() {
    return {
      EnglishLan:false,
      ShowCreateDoctorProfile:false,
         baseURL : domain,
       UploadRequestHeader:mainSetting.UploadRequestHeader,
       uploadUrl:domain+"api/Common/UploadFile",
       Model:{},
       search:{},
       searchDoctorModel:{},
       HospitalDoctors:[],
       HospitalChiledSpecialties:[],
       HospitalParentSpecialties:[],
       HospitalSurgeries:[],
      selectedChiledSpecialityFilter:{ID:0},
       selectedSpecialityFilter:{ID:0},
       selectedSurgeryFilter:{ID:0},
    };
  },
  components:{
    CreateDoctorProfile
  },
  computed: {
 parentSpecialties() {
      return this.$store.state.SpecialtyList.parentSpecialties;
    },
    childSpecialties() {
      return this.$store.state.SpecialtyList.childSpecialties;
    },
    searchDoctors() {
      return this.$store.state.DoctorList.searchDoctors;
    },
     searchSurgeries() {
      return this.$store.state.HospitalList.searchSurgeries;
    },
     filterDoctor()
        {
            if(this.search.DoctorName)
            {
                var vm=this;
                return this.searchDoctors.filter(function(obj){
                    return obj.Name.toLowerCase().indexOf(vm.search.DoctorName.toLowerCase()) > -1
                });
            }
            else
             return this.searchDoctors
        },
  },
  methods: {
    searchDoctor() {
      this.$store.dispatch("DoctorList/SearchDoctors", this.searchDoctorModel).then(()=>{
        debugger

         this.HospitalDoctors.forEach(element => {
              debugger
            var index =    this.searchDoctors.findIndex(x=>x.ID==element.DoctorID);
            if(index!=-1){
            this.searchDoctors[index].IsSelected=true;
            }
            });
      });
    },
    select(item){
      debugger
this.selectedSpecialityFilter=item;
 this.searchDoctorModel.SpecialtyID = item.SpecialtyID;
 this.searchDoctorModel.HospitalSpecialityID = item.ID;
this.selectedChiledSpecialityFilter={};
 this.searchDoctor();


this.GetChiledSpecialities(item);
// this.searchDoctor();
    },
    selectChiled(item){
  this.selectedChiledSpecialityFilter=item;
   this.searchDoctorModel.SpecialtyID = item.SpecialtyID;
 this.searchDoctorModel.HospitalSpecialityID = item.ID;
 this.searchDoctor();

    },
    selectSurgery(item){
        this.selectedSurgeryFilter = item;
        this.searchDoctorModel.HospitalSurgeryID = item.ID;
        this.GetHospitalSurgeriesDoctor();
    },
    searchSurgies(){
      this.searchDoctorModel.HospitalID = this.$store.state.AppActiveUser.Hospital.ID;
  this.$store.dispatch("HospitalList/SearchHospitalSurgies", this.searchDoctorModel).then(()=>{

        debugger
          });
    },
    GetChiledSpecialities(item) {
      var data ={};
      data.HospitalID = this.$store.state.AppActiveUser.Hospital.ID;
      data.SpecialityID =  item.SpecialtyID;
      this.$store.dispatch(
        "HospitalList/GetHospitalChiledSpecialty",
       data
      ).then(res=>{
        this.HospitalChiledSpecialties = res.data.Data;
          // this.HospitalDoctors.forEach(element => {
          //     debugger
          //   var index =    this.childSpecialties.findIndex(x=>x.ID==element.SpecialtyID);
          //  this.childSpecialties[index].IsSelected =  true;
          //   });
      });
    },


    back() {
      this.$router.go(-1);
    },
  DoctorChecked(item){
    debugger
   if(item.IsSelected==true){
     this.AddHospitalDoctor(item);
   }
   else{
    this.DeleteHospitalDoctor(item)
   }
  },
  DeleteHospitalDoctor(item){
    var index =    this.HospitalDoctors.findIndex(x=>x.DoctorID==item.ID);
      this.HospitalDoctors.splice(index, 1)

  },
    AddHospitalDoctor(item){
   var index =    this.HospitalDoctors.findIndex(x=>x.DoctorID==item.ID);
          if(index>=0){
            //alert

          }
          else{
            var hospitalDoctor = {};
            hospitalDoctor.Doctor={};
            hospitalDoctor.Doctor.Name = item.Name;
            hospitalDoctor.Doctor.ID = item.ID;
             hospitalDoctor.DoctorID = item.ID;
             hospitalDoctor.HospitalSurgeryID = this.searchDoctorModel.HospitalSurgeryID;
            hospitalDoctor.HospitalID = this.$store.state.AppActiveUser.Hospital.ID;
            this.HospitalDoctors.push(hospitalDoctor)
          }
  },
  GetHospitalSurgeriesDoctor(){
this.$store.dispatch("HospitalList/GetHospitalSurgeryDoctors", this.searchDoctorModel.HospitalSurgeryID).then(res=>{
      this.HospitalDoctors = res.data.Data;
            this.$vs.loading.close();
             this.HospitalDoctors.forEach(element => {
              debugger
            var index =    this.searchDoctors.findIndex(x=>x.ID==element.DoctorID);
            if(index!=-1){
            this.searchDoctors[index].IsSelected=true;
            }
            });
    });
  },
  SaveDoctors(){
          this.$vs.loading();

          debugger
if(this.HospitalDoctors.length>0){
this.$store.dispatch("HospitalList/SaveHospitalSugeryDoctors", this.HospitalDoctors).then(res=>{
      if(res.status==200){
            this.$vs.loading.close();
            window.showSuccess();
        //  this.back();
      }
            this.$vs.loading.close();


    });
}
else{
     this.$store.dispatch(
        "HospitalList/DeleteOldHospitalSurgeryDoctors",
       this.searchDoctorModel.HospitalSurgeryID
      ).then(res=>{
      if(res.status==200){
            this.$vs.loading.close();
            window.showSuccess();
        //  this.back();
      }
            this.$vs.loading.close();


    });
}

  }
  },
  created() {
        this.EnglishLan=localStorage.getItem("SaveLang")=="en"?true:false;

     if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
 if(!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    this.$store.commit("SpecialtyList/SET_ChildSpecialty", []);

    this.$store.dispatch("SpecialtyList/GetParentSpecialty");
            this.$vs.loading();

    this.$store.dispatch("HospitalList/GetHospitalParentSpecialty", this.$store.state.AppActiveUser.Hospital.ID).then(res=>{
      this.HospitalParentSpecialties = res.data.Data;
            this.$vs.loading.close();
    });


    this.searchDoctorModel.CountryID = this.$store.state.AppActiveUser.Hospital.CountryID;
    // this.searchDoctor();
 this.searchSurgies();

  },
};
</script>

<style >

.Active{
       color: #004477;
       padding: 0px;
       border-radius: 12px;
   }
   .InActive{
      padding: 0px;
       border-radius: 12px;
   }
  p{
    font-size: medium;
  }

</style>
